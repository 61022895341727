const accountTypes = [
  'Savings',
  'Personal Current',
  'Corporate Current',
  'Fixed Deposit',
  'Target',
  'Loan',
  'Esusu',
  'Savings Group',
  'Savings Staff',
  'Personal Current Staff',
  'Savings Special',
  'Corporate Current Coop'
];

export default accountTypes;
