import { useStore } from "react-redux";
import axios from "axios";

const apiURL = "https://api.jekalo.com/api/transfers";
const apiURL1 = "https://api.jekalo.com/api";

export const saveSalaryHistories = (salaryHistories) => {
	return {
		type: "SAVE_SALARY_HISTORIES",
		salaryHistories,
	};
};
export const saveLoanHistories = (loanHistories) => {
	return {
		type: "SAVE_LOAN_HISTORIES",
		loanHistories,
	};
};

export const getSalaries = (orgId, cb) => {
	// const user = useStore.getState().authReducer.userData;
	return (dispatch) => {
		axios
			.get(apiURL + "/salary", { params: orgId })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					console.log(response);
					const salaryHistories = response.data;
					// console.log('paysalaryHistoriesload', salaryHistories);
					dispatch(saveSalaryHistories(salaryHistories));
					cb && cb("success");
				}
			})
			.catch((err) => {
				// console.log(err.response);
				cb && cb("failed");
			});
	};
};

export const getUsersLoan = (orgId, cb) => {
	// const user = useStore.getState().authReducer.userData;
	return (dispatch) => {
		axios
			.get(apiURL + "/loan", { params: orgId })
			.then((response) => {
				if (response.status === 200) {
					console.log(response);
					const loanHistories = response.data;
					console.log("paysalaryHistoriesload", loanHistories);
					dispatch(saveLoanHistories(loanHistories));
					cb && cb("success");
				}
			})
			.catch((err) => {
				// console.log(err.response);
				cb && cb("failed");
			});
	};
};
export const postTransferFile = (payload, cb) => {
	// const user = useStore.getState().authReducer.userData;
	return (dispatch) => {
		axios
			.post(apiURL + "/create2", payload)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					console.log(response);
					const loanHistories = response.data;
					console.log("paysalaryHistoriesload", loanHistories);
					cb && cb("success");
				}
			})
			.catch((err) => {
				console.log(err.response);
				cb && cb(err.response.data.message);
			});
	};
};

export const postMandateFile = (payload, cb) => {
	// const user = useStore.getState().authReducer.userData;
	return (dispatch) => {
		axios
			.post(apiURL1 + "/recurring/upload", payload)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					cb && cb("success");
				}
			})
			.catch((err) => {
				console.log(err.response);
				cb && cb(err.response.data.message);
			});
	};
};


export const postBulkTransaction = (payload, cb) => {
	// const user = useStore.getState().authReducer.userData;
	return (dispatch) => {
		axios
			.post(apiURL1 + "/posting/upload", payload)
			.then((response) => {
				console.log("response success upload", response);
				if (response.status === 200 || response.status === 201) {
					cb && cb("success");
				}
			})
			.catch((err) => {
				console.log(err.response);
				cb && cb(err.response.data.message);
			});
	};
};