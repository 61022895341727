import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Pagination from "./Pagination";
import { paginate } from "./utils/paginate";
import Loader from "./Loader";
import FilterView from "./FilterView";
import Modal from "react-modal";
import Spinner from "react-spinner-material";

import {
  Button,
  Container,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import {
  getMandate,
  nddAutoRepayment,
  createSetupReccurring,
  getReccurring,
  addNewUser,
  createMandate,
  updateMandateStatus,
  updateCif,
  chargeAmount,
} from "../../../reducers/home";

import { setErrorMessage } from "./../../../actions/error";

import { toast } from "react-toastify";
import { notify } from "./utils/notifications";
import { navPermission } from "../../../actions/permission";
import { postMandateFile } from "../../../actions/bulkTransferActions";
import {
  updateTransfersStatus,
  getUserIdAndPhoneId,
} from "./../../../reducers/transfers";

Modal.setAppElement("#root");

const colStyle = {
  // whiteSpace: 'nowrap'
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class AutoRepayment extends Component {
  state = {
    chargeAmt: "",
    status: "",
    loading: false,
    transfers: [],
    transfersAll: [],
    modalIsOpen: false,
    token: "",
    submitAuthorization: false,
    filterObj: {},
    accountName: "Account Name",
    accountNumber: "Account Number",
    bvn: "BVN",
    accountDesignation: "Designation",
    accountType: "Account Type",
    bankName: "Bank Name",
    created_at: "Created_at",
    cifHeader: "CIF",
    accountStatus: "Status",
    pageSize: 10,
    currentPage: 1,
    pageHistory: {
      currentPage: 1,
      totalPages: 0,
    },
    payment_id: "",
    account_id: "",
    orgId: "",
    getReccurring: [],
    getMandate: [],
    editmandate: {
      id: "",
      accountNumber: "",
      bankCode: "",
      payerName: "",
      accountName: "",
      amount: "",
      mandateCode: "",
    },
    editReccurring: {
      loan_amount: "",
      repayment_amount: "",
      day_of_the_month: new Date().getDate(),
      frequency: "monthly",
      meter: "",
      tenor: "",
      down_payment: 0,
      adv_month: 0,
      meter_cost: 0,
      down_payment_noVAT: 0,
    },
    selectedFile: null,
    userId: "",
    phoneId: "",
    cif: "",
    idCharge: 0,
    userId: "",
    phoneId: "",
    initiatorID: "",
    initiatorName: "",
  };

  notify = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
      onClose: this.props.setErrorMessage(null),
    });
  };

  // componentDidMount = async () => {

  //   this.setState({ loading: true });
  //   const { user } = window.localStorage;
  //   // const { org_id: orgId } = JSON.parse(user);

  //   const { phone, org_id: orgId } = JSON.parse(user);
  //   const ids = await this.props.getUserIdAndPhoneId({ phone });

  //   const { phone_id: phoneId, user_id: userId } = ids;

  //   this.setState({
  //     phoneId,
  //     userId,
  //     orgId,
  //   });

  //   if (!navPermission(2)) {
  //     return (window.location.href = "/");
  //   } else {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     this.setState({ user }, () => {});
  //     //await this.props.nddAutoRepayment({ orgId });

  //     try {
  //       const transfers = await this.props.nddAutoRepayment({
  //         orgId,
  //         ...this.state.filterObj,
  //       });

  //       if (transfers.error) {
  //         notify(transfers.message, "error");
  //       } else {
  //         const {
  //           total_count: totalCount,
  //           offset,
  //           limit,
  //         } = transfers.pagination;
  //         const totalPages = Math.ceil(totalCount / this.state.pageSize);
  //         const currentPage = this.state.currentPage;
  //         this.setState({
  //           transfersAll: transfers,
  //           transfers: paginate(
  //             transfers.data,
  //             currentPage,
  //             this.state.pageSize
  //           ),
  //           pageHistory: { currentPage, totalPages },
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //     this.setState({ loading: false });
  //   }
  // };

  componentDidMount = async () => {
    this.setState({ loading: true });

    let { user } = window.localStorage;
    user = JSON.parse(user);
    const { phone, org_id: orgId, firstname, lastname, id } = user;
    if (!navPermission(2)) {
      return (window.location.href = "/");
    } else {
      const ids = await this.props.getUserIdAndPhoneId({ phone });
      const { phone_id: phoneId, user_id: userId } = ids;

      this.setState({
        phoneId,
        userId,
        orgId,
        loading: false,
        initiatorID: id,
        initiatorName: `${firstname} ${lastname}`,
        user,
      });

      try {
        const transfers = await this.props.nddAutoRepayment({
          orgId,
          ...this.state.filterObj,
        });

        if (transfers.error) {
          notify(transfers.message, "error");
        } else {
          const {
            total_count: totalCount,
            offset,
            limit,
          } = transfers.pagination;
          const totalPages = Math.ceil(totalCount / this.state.pageSize);
          const currentPage = this.state.currentPage;
          this.setState({
            transfersAll: transfers,
            transfers: paginate(
              transfers.data,
              currentPage,
              this.state.pageSize
            ),
            pageHistory: { currentPage, totalPages },
          });
        }
      } catch (err) {
        console.log(err);
      }
      this.setState({ loading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.showAllIcadAccountDetails !==
      this.props.showAllIcadAccountDetails
    ) {
    }
    if (prevProps.showUserReccurring !== this.props.showUserReccurring) {
      console.log(
        "got here showUserReccurring!",
        this.props.showUserReccurring
      );
    }
    if (
      prevProps.showCreateAllReccurring !== this.props.showCreateAllReccurring
    ) {
      this.setState({ editReccurring: {} }, () => {
        toast.error(this.props.showCreateAllReccurring.message);
      });
    }
    if (prevProps.showNewUser !== this.props.showNewUser) {
      const { error, data } = this.props.showNewUser;
      if (data && Object.keys(data).length) {
        // const { createAdmin } = data;
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error("User added succesfully !!!");
          this.handleCloseModal();
        });
      } else if (error && Object.keys(error).length) {
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error("Network error!");
        });
      }
    }
  }

  refreshList = async () => {
    this.setState({ loading: true });
    try {
      const transfers = await this.props.nddAutoRepayment({
        orgId: this.state.orgId,
        ...this.state.filterObj,
      });
      /*
      const transfers = await this.props.getAllTransfers({
        orgId: this.state.orgId,
        ...this.state.filterObj,
        ...this.state.paginationObj
      });
      */
      if (transfers.error) {
        notify(transfers.message, "error");
      } else {
        const { total_count: totalCount, offset, limit } = transfers.pagination;
        //TODO:
        /*
        const totalPages = Math.ceil(totalCount / limit);
        const currentPage = offset / limit + 1;
        */

        const totalPages = Math.ceil(totalCount / this.state.pageSize);
        const currentPage = 1;
        this.setState({
          currentPage,
          transfersAll: transfers,
          transfers: paginate(transfers.data, currentPage, this.state.pageSize),
          pageHistory: { currentPage, totalPages },
        });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };
  onFilter = (payload) => {
    this.setState(
      {
        filterObj: payload,
      },
      this.refreshList
    );
  };

  handlePageChange = (page) => {
    const transfersAll = this.state.transfersAll;
    const { total_count: totalCount, offset, limit } = transfersAll.pagination;
    const totalPages = Math.ceil(totalCount / this.state.pageSize);
    const currentPage = page;
    this.setState({
      currentPage: page,
      transfers: paginate(transfersAll.data, currentPage, this.state.pageSize),
      pageHistory: { currentPage, totalPages },
    });
  };
  handlegetReccurring = async (payment_id, edit_mandate) => {
    this.setState({ loading: true });
    const getReccurring = await this.props.getReccurring(payment_id);
    this.setState(
      { payment_id, editmandate: edit_mandate, getReccurring, loading: false },
      () => {}
    );
  };
  //   handlegetMandate = (amount) => {
  //     const { editmandate } = this.state;
  //     this.setState(
  //       {
  //         editmandate: {
  //           id: editmandate.id,
  //           accountNumber: editmandate.accountNumber,
  //           bankCode: editmandate.bankCode,
  //           payerName: editmandate.payerName,
  //           accountName: editmandate.accountName,
  //           mandateCode: editmandate.mandateCode,
  //           amount: amount,
  //         },
  //       },
  //       () => {
  //         this.props.getMandate({ account_id: editmandate.accountNumber });
  //       }
  //     );
  //   };

  handlegetMandate = async (amount) => {
    console.log("mandate amount", amount)
    const { editmandate } = this.state;
    // console.log("editmandate", editmandate);
    // this.setState(
    //   {
    //     editmandate: {
    //       id: editmandate.id,
    //       accountNumber: editmandate.accountNumber,
    //       bankCode: editmandate.bankCode,
    //       payerName: editmandate.payerName,
    //       accountName: editmandate.accountName,
    //       mandateCode: editmandate.mandateCode,
    //       amount: amount,
    //     },
    //   },
    //   async () => {
    //    const mandate = await this.props.getMandate({ account_id: editmandate.accountNumber });
    //    console.log("mandate", mandate);
    //   }
    // );
    // console.log("after setting mandate", editmandate);

    this.setState({
      loading: true,
      editmandate: {
        id: editmandate.id,
        accountNumber: editmandate.accountNumber,
        bankCode: editmandate.bankCode,
        payerName: editmandate.payerName,
        accountName: editmandate.accountName,
        mandateCode: editmandate.mandateCode,
        amount: amount,
      },
    });

    const mandate = await this.props.getMandate({
      account_id: editmandate.accountNumber,
    });

    this.setState({
      getMandate: mandate,
      loading: false,
    });

    console.log("mandate data state", this.state);
  };

  handleEditMandate = async () => {
    console.log("hitting update mandate");

    let { editmandate, orgId, email_mandate, phone_mandate } = this.state;

    console.log("edit mandate amount", editmandate);

    editmandate = {
      ...editmandate,
      orgId,
      email_mandate,
      phone_mandate,
    };

    console.log("editmandate", editmandate);

    this.setState({
      loading: true,
    });

    try {
      const mandateUpdated = await this.props.createMandate(editmandate);

      console.log("mandateUpdated", mandateUpdated);

      if (mandateUpdated) {
        const newMandateData = await this.props.getMandate({
          account_id: editmandate.accountNumber,
        });

        console.log("newMandateData", newMandateData);
        this.setState({
          getMandate: newMandateData,
          loading: false,
          email_mandate: "",
          phone_mandate: "",
        });
        toast.success("Mandate added successfully");
      }
    } catch (error) {
      console.log("error updating mandate", error.message);
      this.setState({
        loading: false
      })
      toast.error(error.message);
    }
  };

  handleCloseModal = () => {
    "[data-dismiss=modal]".trigger({ type: "click" });
  };

  onExport = (data) => {
    console.log("Export data ", data);
    const { orgId } = this.state;
    for (let propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ""
      ) {
        delete data[propName];
      }
    }
    if (data.dateFilter === "interval") {
      if (!data.startDate || !data.endDate) {
        this.notify("Please specify both the start and end date");
        return;
      }
      const exportToCSVLink = `https://api.jekalo.com/api/icad/csv_export?org_id=${orgId}&date_filter=${data.dateFilter}&start_date=${data.startDate}&end_date=${data.endDate}`;
      // console.log(exportToCSVLink);
      window.location.href = exportToCSVLink;
    } else if (data.dateFilter === "specific") {
      if (!data.specificDate) {
        this.notify("Please specify the specific date");
        return;
      }
      const exportToCSVLink = `https://api.jekalo.com/api/icad/csv_export?org_id=${orgId}&date_filter=specific&specific_date=${data.specificDate}`;
      // console.log(exportToCSVLink);
      window.location.href = exportToCSVLink;
    } else if (data.dateFilter === "today") {
      const exportToCSVLink = `https://api.jekalo.com/api/icad/csv_export?org_id=${orgId}&date_filter=today`;
      // console.log(exportToCSVLink);
      window.location.href = exportToCSVLink;
    } else {
      let exportToCSVLink = `https://api.jekalo.com/api/icad/csv_export?org_id=${orgId}`;
      window.location.href = exportToCSVLink;
      // console.log(exportToCSVLink);
    }
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  };

  closeModal = () => {
    if (this.state.submitAuthorization) {
      return;
    }
    this.setState({ modalIsOpen: false, token: "", status: "" });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  authorizeTransaction = async (event) => {
    this.setState({ submitAuthorization: true });
    const { chargeAmount } = this.props;
    try {
      const {
        token,
        status,
        userId,
        phoneId,
        orgId,
        initiatorName,
        initiatorID,
        idCharge,
        chargeAmt,
      } = this.state;

      const payload = {
        token,
        status,
        userId,
        phoneId,
        orgId,
        initiatorID,
        initiatorName,
        idCharge,
        chargeAmt,
      };
      await chargeAmount(payload);
      const res = this.props.chargeAmountResponse;
      if (res.error) {
        this.notify(res.message, "error");
      } else {
        this.notify(res.message, "success");
        this.setState({ submitAuthorization: false });
        this.closeModal();
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ submitAuthorization: false });
  };

  populateAllAcountDetails = () => {
    const { transfers } = this.state;
    console.log("transfer ", transfers);
    const transactionsList = transfers.map((accountDetails) => {
      const {
        accountName,
        accountNumber,
        bankName,
        accountDesignationName,
        accountTypeName,
        bvn,
        created_at,
        bankCode,
        id,
        cif,
        accountStatus,
      } = accountDetails;
      const editmandate = {
        id,
        accountNumber,
        bankCode,
        payerName: accountName,
        accountName: accountName,
        amount: "",
        mandateCode: "",
      };
      return (
        <tr key={id}>
          <td>{accountName}</td>
          <td>{accountNumber}</td>
          <td>{bankName}</td>
          <td>{accountDesignationName}</td>
          <td>{accountTypeName}</td>
          <td>{cif ? cif : "-"}</td>
          <td>{accountStatus}</td>
          <td>{created_at}</td>

          <td>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal1"
              onClick={() => {
                this.setState({ cif: cif ? cif : "" });
                this.handlegetReccurring(bvn, editmandate);
              }}
            >
              Setup Recurring Payment
            </button>
          </td>
        </tr>
      );
    });
    return transactionsList;
  };

  populateGetReccurringDetails = () => {
    const reccurringList =
      this.state.getReccurring &&
      this.state.getReccurring.map((payload) => {
        const {
          payment_id,
          loan_amount,
          repayment_amount,
          day_of_the_month,
          created_at,
          frequency,
        } = payload;
        return (
          this.state.getReccurring && (
            <tr key={created_at}>
              <td>{loan_amount}</td>
              <td>{`₦${repayment_amount}`}</td>
              <td>{day_of_the_month}</td>
              <td>{created_at}</td>
              <td>{frequency}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal2"
                  onClick={() => this.handlegetMandate(loan_amount)}
                >
                  Setup Mandate
                </button>
              </td>
            </tr>
          )
        );
      });
    return reccurringList;
  };
  
  populateGetMandateDetails = (getMandate) => {
    const mandateList =
      getMandate &&
      getMandate.map((payload) => {
        const {
          id,
          bankCode,
          payerName,
          accountNumber,
          amount,
          mandateCode,
          responseDescription,
          workFlowStatusDescription,
        } = payload;

        //refresh mandate status

        this.props.updateMandateStatus({
          mandateCode: mandateCode,
          orgId: this.state.orgId,
        });

        //refresh mandate status

        const responseDesc = responseDescription.replace(
          "Welcome to NIBSS e-mandate authentication service, a seamless and convenient authentication experience.",
          ""
        );
        return (
          getMandate && (
            <tr key={id}>
              <td>{bankCode}</td>
              <td>{`₦${amount}`}</td>
              <td>{payerName}</td>
              <td>{accountNumber}</td>
              <td>{responseDesc}</td>
              <td>{mandateCode}</td>
              <td>{workFlowStatusDescription}</td>

              {workFlowStatusDescription == "Bank Approved" && (
                <td>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal21"
                    onClick={() => {
                      this.setState({ status: "pending", idCharge: id });
                    }}
                  >
                    Charge
                  </button>
                </td>
              )}
            </tr>
          )
        );
      });
    return mandateList;
  };

  // fillReccuringForm = (text, type) => {
  // 	const { editReccurring } = this.state;
  // 	let meterCost = 0;
  // 	if (type == "meter") {
  // 		if (text == "single") {
  // 			meterCost = 44896.17;
  // 		} else {
  // 			meterCost = 82855.19;
  // 		}
  // 		const downPayment = (0.2 * meterCost).toFixed(2),
  // 			downPaymentVAT = (1.075 * downPayment).toFixed(2);
  // 		this.setState(
  // 			{
  // 				editReccurring: {
  // 					down_payment: downPaymentVAT,
  // 					down_payment_noVAT: downPayment,
  // 					meter_cost: meterCost,
  // 					meter: text,
  // 					loan_amount: editReccurring.loan_amount,
  // 					repayment_amount: editReccurring.repayment_amount,
  // 					day_of_the_month: editReccurring.day_of_the_month,
  // 					frequency: editReccurring.frequency,
  // 					adv_month: editReccurring.adv_month,
  // 					tenor: editReccurring.tenor,
  // 				},
  // 			},
  // 			() => {
  // 				console.log(this.state.editReccurring);
  // 			}
  // 		);
  // 	} else if (type == "tenor") {
  // 		const tenor = text * 1,
  // 			P = editReccurring.meter_cost - editReccurring.down_payment_noVAT,
  // 			R = 21 / (12 * 100),
  // 			N = tenor;
  // 		console.log(P);
  // 		console.log(R);
  // 		console.log(N);
  // 		let EMI = (P * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
  // 		EMI = EMI.toFixed(2);
  // 		const loanAmount = (EMI * tenor).toFixed(2);
  // 		console.log(EMI);
  // 		this.setState({
  // 			editReccurring: {
  // 				repayment_amount: EMI,
  // 				tenor: text,
  // 				meter_cost: editReccurring.meterCost,
  // 				meter: editReccurring.meter,
  // 				loan_amount: loanAmount,
  // 				day_of_the_month: editReccurring.day_of_the_month,
  // 				frequency: editReccurring.frequency,
  // 				down_payment: editReccurring.down_payment,
  // 				down_payment_noVAT: editReccurring.down_payment_noVAT,
  // 				adv_month: editReccurring.adv_month,
  // 			},
  // 		});
  // 	} else {
  // 		this.setState(
  // 			{
  // 				editReccurring: {
  // 					loan_amount: type === "loan_amount" ? text : editReccurring.loan_amount,
  // 					repayment_amount: type === "repayment_amount" ? text : editReccurring.repayment_amount,
  // 					day_of_the_month: type === "day_of_the_month" ? text : editReccurring.day_of_the_month,
  // 					frequency: type === "frequency" ? text : editReccurring.frequency,
  // 					tenor: type === "tenor" ? text : editReccurring.tenor,
  // 					adv_month: type === "adv_month" ? text : editReccurring.adv_month,
  // 					meter: type === "meter" ? text : editReccurring.meter,
  // 					meter_cost: meterCost > 0 ? meterCost : editReccurring.meter_cost,
  // 					down_payment: editReccurring.down_payment,
  // 					down_payment_noVAT: editReccurring.down_payment_noVAT,
  // 				},
  // 			},
  // 			() => {
  // 				console.log(this.state.editReccurring);
  // 			}
  // 		);
  // 	}
  // };

  fillReccuringForm = (text, type) => {
    const { editReccurring } = this.state;
    let meterCost = 0;
    let formattedText = text;

    // Handle formatting for monetary fields
    if (type === "loan_amount" || type === "repayment_amount") {
      // Remove non-numeric characters except the decimal point
      const numericValue = text.replace(/[^0-9.]/g, "");
      // Format as number with commas
      formattedText = numericValue
        ? parseFloat(numericValue).toLocaleString("en-US")
        : "";
    }

    if (type === "meter") {
      if (text === "single") {
        meterCost = 44896.17;
      } else {
        meterCost = 82855.19;
      }
      const downPayment = (0.2 * meterCost).toFixed(2),
        downPaymentVAT = (1.075 * downPayment).toFixed(2);
      this.setState(
        {
          editReccurring: {
            ...editReccurring,
            down_payment: downPaymentVAT,
            down_payment_noVAT: downPayment,
            meter_cost: meterCost,
            meter: text,
          },
        },
        () => {
          console.log(this.state.editReccurring);
        }
      );
    } else if (type === "tenor") {
      const tenor = text * 1,
        P = editReccurring.meter_cost - editReccurring.down_payment_noVAT,
        R = 21 / (12 * 100),
        N = tenor;
      let EMI = (P * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
      EMI = EMI.toFixed(2);
      const loanAmount = (EMI * tenor).toFixed(2);
      this.setState({
        editReccurring: {
          ...editReccurring,
          repayment_amount: EMI,
          tenor: text,
          loan_amount: loanAmount,
        },
      });
    } else {
      this.setState(
        {
          editReccurring: {
            ...editReccurring,
            loan_amount:
              type === "loan_amount"
                ? formattedText
                : editReccurring.loan_amount,
            repayment_amount:
              type === "repayment_amount"
                ? formattedText
                : editReccurring.repayment_amount,
            day_of_the_month:
              type === "day_of_the_month"
                ? text
                : editReccurring.day_of_the_month,
            frequency: type === "frequency" ? text : editReccurring.frequency,
            tenor: type === "tenor" ? text : editReccurring.tenor,
            adv_month: type === "adv_month" ? text : editReccurring.adv_month,
            meter: type === "meter" ? text : editReccurring.meter,
            meter_cost: meterCost > 0 ? meterCost : editReccurring.meter_cost,
            down_payment: editReccurring.down_payment,
            down_payment_noVAT: editReccurring.down_payment_noVAT,
          },
        },
        () => {
          console.log(this.state.editReccurring);
        }
      );
    }
  };

  createSetupReccurring = async () => {
    const { loan_amount, repayment_amount, day_of_the_month, frequency } =
      this.state.editReccurring;
    const { payment_id } = this.state;
    if (loan_amount === undefined || loan_amount.length < 1) {
      this.setState({ loginError: "Loan Amount can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (repayment_amount === undefined || repayment_amount.length < 1) {
      this.setState({ loginError: "Repayment amount can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (day_of_the_month === undefined || day_of_the_month.length < 1) {
      this.setState({ loginError: "Date can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else {
      const payload = {
        payment_id,
        loan_amount,
        repayment_amount,
        day_of_the_month,
        created_at: Date.now(),
        frequency,
      };
      const newEditRecurring = {
        loan_amount: "",
        repayment_amount: "",
        day_of_the_month: "",
        frequency: "monthly",
      };
      this.setState({ loading: true });
      await this.props.createSetupReccurring(payload);

      const getReccurring = await this.props.getReccurring(payment_id, true);

      this.setState(
        {
          editReccurringSaveBtn: true,
          editReccurring: newEditRecurring,
          getReccurring,
          loading: false,
        },
        () => {
          toast.success("Setup Reccurring added successfully");
          this.handleCloseModal();
        }
      );
    }
  };

  handleEditReccurring = async () => {
    await this.createSetupReccurring();
  };

  //   handleEditMandate = () => {
  //     let { editmandate, orgId } = this.state;
  //     console.log("editmandate", editmandate);
  //     editmandate = { ...editmandate, orgId };
  //     this.props.createMandate(editmandate);
  //     toast.success("Mandate added successfully");
  //   };

  handleCloseModal = () => {
    const closeButton = document.querySelector("#exampleModal51");
    if (closeButton) {
      closeButton.click();
    }
  };

  handleChangeFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });

    console.log("selectedFile", this.state.selectedFile);
  };

  onFileUpload = () => {
    console.log("onFileUpload ", this.state.selectedFile);
    if (this.state.selectedFile) {
      // Create an object of formData
      const formData = new FormData();

      const { token, userId, phoneId, orgId } = this.state;

      // Update the formData object
      formData.append("userId", userId);
      formData.append("phoneId", phoneId);
      formData.append("orgId", orgId);
      formData.append("token", token);
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      // Display formData value by the key/value pairs
      // for (let pair of formData.entries()) {
      // 	console.log(pair[0], pair[1]);
      // }

      this.setState({ loading: true }, () => {
        this.props.postMandateFile(formData, this.handleCallback);
      });
    }
  };

  handleCallback = async (res) => {
    if (res === "success") {
      await this.refreshList();
      this.setState({ submitAuthorization: false });
      this.closeModal();
      toast.success("File uploaded successful");
      this.setState(
        {
          loading: false,
        },
        () => {}
      );
    } else {
      notify(res, "error");
      this.setState({ loading: false });
    }
  };

  handleInputChange = (text) => {
    // Remove all non-numeric characters
    const numericValue = text.replace(/[^0-9]/g, "");

    // Update the state with the numeric value
    this.setState({ cif: numericValue });

    return numericValue;
  };

  updateCif = async () => {
    // console.log("updateCif ", this.state.cif);

    const res = await this.props.updateCif({
      cif: this.state.cif,
      icadId: this.state.editmandate.id,
      org_id: this.state.orgId,
    });

    console.log("updateCif ", res);

    if (res.error) {
      notify(res.message, "error");
    } else {
      await this.refreshList();
      notify(res.message, "success");
    }
  };

  render() {
    const { showUserReccurring, showAllMandateDetails } = this.props;
    const {
      pageSize,
      currentPage,
      loading,
      submitAuthorization,
      pageHistory,
      token,
    } = this.state;
    //const datas = paginate(showAllIcadAccountDetails, currentPage, pageSize);
    const tokenError = token.length !== 6;
    const showAllIcadAccountDetails = this.populateAllAcountDetails();

    console.log("getMandate", this.state.getMandate);

    return (
      <Container>
        <Row>{loading && <Loader />}</Row>
        <Row>
          <Col className="d-flex justify-content-start align-items-center">
            <Button
              className="text-uppercase d-flex align-items-center mr-1"
              color="success"
              onClick={this.refreshList}
            >
              <i className="fas fa-redo mr-2" style={{ fontSize: 10 }}></i>{" "}
              <small className="font-weight-bold">Refresh</small>
            </Button>

            <FilterView onFilter={this.onFilter} />
            <FilterView
              onFilter={this.onExport}
              label={"Export as CSV"}
              exclude={["keyword", "status"]}
            />
            {/* <CsvUploader /> */}

            {/* {(!(isBroad("") || orgNotAllowed("")) || !exactViewPermission("approvals")) && ( */}
            <div className="flex-1">
              <label
                className="text-uppercase d-flex align-items-center float-right mr-2"
                color="success"
              >
                &nbsp;
                <small className="font-weight-bold">
                  Bulk - [Click to upload]{" "}
                </small>
                {this.state.selectedFile && (
                  <small className="text-lowercase font-weight-normal text-info">
                    &nbsp;{this.state.selectedFile.name}
                  </small>
                )}
                <Input
                  accept=".csv"
                  onChange={this.handleChangeFile}
                  type="file"
                  name="id_image"
                  className="d-none"
                />
                &nbsp;
                <Button
                  className="text-uppercase d-flex align-items-center mr-1"
                  color="success"
                  onClick={() => {
                    if (this.state.selectedFile) {
                      this.setState({ modalIsOpen: true });
                    } else {
                      notify("FIle not yet uploaded", "error");
                    }
                  }}
                >
                  Upload!
                </Button>
              </label>
            </div>
            {/* )} */}
            <div className="row">
              {loading && <Loader />}
              <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <h2
                  className="d-none"
                  ref={(subtitle) => (this.subtitle = subtitle)}
                >
                  Please enter your token
                </h2>
                <div class="d-flex justify-content-end">
                  <div
                    onClick={this.closeModal}
                    style={{ padding: 5, cursor: "pointer" }}
                  >
                    <i className="fas fa-close text-danger"></i>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Please enter your token</h6>
                  <input
                    type="text"
                    className="form-control"
                    name="token"
                    value={this.state.token}
                    onChange={this.handleChange}
                  />
                  <button
                    onClick={this.onFileUpload}
                    className="btn btn-primary d-flex justify-content-center align-items-center w-100 mt-2"
                    disabled={tokenError || submitAuthorization}
                  >
                    {!submitAuthorization && (
                      <small className="font-weight-bold text-uppercase">
                        {this.state.status === "rejected"
                          ? "Reject"
                          : "Initiate"}
                      </small>
                    )}
                    {submitAuthorization && (
                      <Spinner
                        size={16}
                        spinnerColor={"#ffffff"}
                        spinnerWidth={2}
                        visible={true}
                      />
                    )}
                  </button>
                </div>
              </Modal>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="p-4">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>{this.state.accountName}</th>
                    <th>{this.state.accountNumber}</th>
                    <th>{this.state.bankName}</th>
                    <th>{this.state.accountDesignation}</th>
                    <th>{this.state.accountType}</th>
                    <th>{this.state.cifHeader}</th>
                    <th>{this.state.accountStatus}</th>
                    <th>{this.state.created_at}</th>
                    <th>{""}</th>
                    <th>{""}</th>
                  </tr>
                </thead>
                <tbody>{showAllIcadAccountDetails}</tbody>
              </table>

              <Row>
                <Col className="d-flex justify-content-end mr-2">
                  {pageHistory.totalPages > 1 && (
                    <Pagination
                      pageHistory={{
                        currentPage,
                        totalPages: pageHistory.totalPages,
                      }}
                      onPageChange={this.handlePageChange}
                    />
                  )}
                </Col>
              </Row>

              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="flex-row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 16,
                          marginBottom: 20,
                        }}
                      >
                        <button
                          className="modal-title btn-success btn-sm"
                          data-toggle="modal"
                          data-target="#exampleModal51"
                        >
                          ADD MORE
                        </button>

                        <div className="d-flex flex-row">
                          <input
                            type="text" // Use text input to allow proper formatting
                            className="form-control mb-1"
                            placeholder="Enter CIF"
                            value={this.state.cif}
                            onChange={(e) =>
                              this.handleInputChange(e.target.value)
                            }
                          />

                          <button
                            style={{ width: 200 }}
                            className="modal-title btn-success btn-sm"
                            onClick={this.updateCif}
                          >
                            Update CIF
                          </button>
                        </div>
                      </div>

                      {(this.state.getReccurring &&
                        this.state.getReccurring.length > 0 && (
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Total Due</th>
                                <th>Repay Amount</th>
                                <th>Pay Day</th>
                                <th>Created At</th>
                                <th>Frequency</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>{this.populateGetReccurringDetails()}</tbody>
                          </table>
                        )) || (
                        <div>
                          <p>No repayment data yet</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* 
							<div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">
											<button type="button" className="btn btn-success" onClick={this.handleEditMandate} disabled={this.state.disableBtn}>
												ADD MORE
											</button>

											{(showAllMandateDetails && showAllMandateDetails.length > 0 && (
												<table className="table">
													<thead>
														<tr>
															<th>Bank Code</th>
															<th>Amount</th>
															<th>Payer Name</th>
															<th>Account</th>
															<th>Message</th>
															<th>Mandate Code</th>
															<th>Status</th>
															<th></th>
														</tr>
													</thead>
													<tbody>{this.populateGetMandateDetails(showAllMandateDetails)}</tbody>
												</table>
											)) || (
												<div>
													<p>No mandate data yet</p>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							*/}

              <div
                className="modal fade"
                id="exampleModal2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <h6>Please enter Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          name="email_mandate"
                          value={this.state.email_mandate}
                          onChange={this.handleChange}
                        />
                        <h6>Please enter Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          name="phone_mandate"
                          value={this.state.phone_mandate}
                          onChange={this.handleChange}
                        />
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={this.handleEditMandate}
                          disabled={this.state.disableBtn}
                        >
                          {this.state.loading ? "Please Wait...." : "ADD MORE"}
                        </button>
                      </div>

                      {(this.state.getMandate &&
                        this.state.getMandate.length > 0 && (
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Bank Code</th>
                                <th>Amount</th>
                                <th>Payer Name</th>
                                <th>Account</th>
                                <th>Message</th>
                                <th>Mandate Code</th>
                                <th>Status</th>
                                <th>{this.state.getMandate.length}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.populateGetMandateDetails(
                                this.state.getMandate
                              )}
                            </tbody>
                          </table>
                        )) || (
                        <div>
                          <p>No mandate data yet</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal21"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <h6>Please enter Amount</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="chargeAmt"
                        value={this.state.chargeAmt}
                        onChange={this.handleChange}
                      />
                      <h6>Please enter your token</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="token"
                        value={this.state.token}
                        onChange={this.handleChange}
                      />
                      <button
                        onClick={this.authorizeTransaction}
                        className="btn btn-primary d-flex justify-content-center align-items-center w-100 mt-2"
                        disabled={tokenError || submitAuthorization}
                      >
                        {!submitAuthorization && (
                          <small className="font-weight-bold text-uppercase">
                            {this.state.status === "rejected"
                              ? "Reject"
                              : "Authorize"}
                          </small>
                        )}
                        {submitAuthorization && (
                          <Spinner
                            size={16}
                            spinnerColor={"#ffffff"}
                            spinnerWidth={2}
                            visible={true}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal51"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    {/* <div className="d-flex flex-row container">
											<label className="justify-content-center mt-1 mr-2">Total Payment Due</label>
											<input
												type="number"
												className="form-control mb-1 col-5 ml-auto"
												placeholder="Total Payment Due"
												value={this.state.editReccurring && this.state.editReccurring.loan_amount ? this.state.editReccurring.loan_amount : ""}
												onChange={(text) => this.fillReccuringForm(text.target.value, "loan_amount")}
											/>
										</div> */}
                    <div className="d-flex flex-row container">
                      <label className="justify-content-center mt-1 mr-2">
                        Total Payment Due
                      </label>
                      <input
                        type="text" // Use text for formatting
                        className="form-control mb-1 col-5 ml-auto"
                        placeholder="Total Payment Due"
                        value={
                          this.state.editReccurring &&
                          this.state.editReccurring.loan_amount
                            ? this.state.editReccurring.loan_amount
                            : ""
                        }
                        onChange={(e) =>
                          this.fillReccuringForm(e.target.value, "loan_amount")
                        }
                      />
                    </div>

                    {/* <div className="d-flex flex-row container">
                      <label className="justify-content-center mt-1 mr-2 ">
                        Repayment Amount
                      </label>
                      <input
                        type="number"
                        className="form-control mb-1 col-5 ml-auto"
                        placeholder="REPAYMENT AMOUNT"
                        value={this.state.editReccurring.repayment_amount}
                        onChange={(text) =>
                          this.fillReccuringForm(
                            text.target.value,
                            "repayment_amount"
                          )
                        }
                      />
                    </div> */}

                    <div className="d-flex flex-row container">
                      <label className="justify-content-center mt-1 mr-2">
                        Repayment Amount
                      </label>
                      <input
                        type="text" // Use text input to allow proper formatting
                        className="form-control mb-1 col-5 ml-auto"
                        placeholder="Repayment Amount"
                        value={
                          this.state.editReccurring &&
                          this.state.editReccurring.repayment_amount
                            ? this.state.editReccurring.repayment_amount
                            : ""
                        }
                        onChange={(e) =>
                          this.fillReccuringForm(
                            e.target.value,
                            "repayment_amount"
                          )
                        }
                      />
                    </div>

                    <div className="d-flex flex-row container">
                      <label className="justify-content-center mt-1 mr-2">
                        Day of the Month
                      </label>
                      <input
                        type="date"
                        className="form-control mb-1 col-5 ml-auto"
                        placeholder="DAY OF THE MONTH"
                        value={this.state.editReccurring.day_of_the_month}
                        onChange={(text) =>
                          this.fillReccuringForm(
                            text.target.value,
                            "day_of_the_month"
                          )
                        }
                      />
                    </div>
                    <div className="d-flex flex-row container">
                      <label className="justify-content-center mt-1 mr-2">
                        Frequency
                      </label>
                      <select
                        className="form-control mb-1 col-5  ml-auto"
                        id="exampleFormControlSelect1"
                        value={this.state.editReccurring.frequency}
                        onChange={(text) =>
                          this.fillReccuringForm(text.target.value, "frequency")
                        }
                      >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                      </select>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.handleEditReccurring}
                        disabled={this.state.disableBtn}
                      >
                        Create Repayment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Create Mandate*/}
          </div>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  showNewAdmin: state.home.showNewAdmin,
  showAllIcadAccountDetails: state.home.showAllIcadAccountDetails,
  showUserReccurring: state.home.showUserReccurring,
  showAllMandateDetails: state.home.showAllMandateDetails,
  showCreateAllReccurring: state.home.showCreateAllReccurring,
  showNewUser: state.home.showNewUser,
  updateTransferStatusResponse: state.transfer.updateTransferStatusResponse,
  chargeAmountResponse: state.home.chargeAmountResponse,
});

const mapDispatchToProps = {
  getMandate,
  nddAutoRepayment,
  createSetupReccurring,
  getReccurring,
  addNewUser,
  createMandate,
  updateMandateStatus,
  updateTransfersStatus,
  postMandateFile,
  getUserIdAndPhoneId,
  chargeAmount,
  updateCif,
  setErrorMessage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoRepayment)
);
