import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Table,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import {
  getAllPosting,
  approvePendingPosting,
} from "../../../actions/postingAction";

import { paginate } from "./utils/paginate";
import Pagination from "./commons/pagination";
import Spinner from "react-spinner-material";
import { notify } from "./utils/notifications";
import { navPermission } from "../../../actions/permission";
import PostingPagination from "../../../Utils/PostingPagination";
import { getTransaction, addNewUser } from "../../../reducers/home";
import { postBulkTransaction } from "../../../actions/bulkTransferActions";

import { getUserIdAndPhoneId } from "./../../../reducers/transfers";

import Loader from "./Loader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class Teller extends Component {
  state = {
    accountName: "Account Name",
    accountNumber: "Account Number",
    amount: "Amount",
    teller_no: "Teller No",
    trans_type: "Trans Type",
    token: "",
    submitAuthorization: false,
    pageSize: 10,
    currentPage: 1,
    modalIsOpen: false,
    loading: false,
    orgId: "",
    phoneId: "",
    userId: "",
    firstname: "",
    lastname: "",
  };

  async componentDidMount() {
    if (!navPermission(2)) {
      return (window.location.href = "/");
    } else {
      const { user } = window.localStorage;
      const {
        org_id: orgId,
        phone,
        firstname,
        lastname,
        id,
      } = JSON.parse(user);

      console.log("user>>>>>", user);

      // const { phone, firstname, lastname, id } = user;

      console.log("user>>>>>", { phone, firstname, lastname, id });

      const ids = await this.props.getUserIdAndPhoneId({ phone });
      const { phone_id: phoneId, user_id: userId } = ids;

      this.setState({
        phoneId,
        userId,
        orgId,
        loading: false,
        initiatorID: id,
        initiatorName: `${firstname} ${lastname}`,
        user,
      });

      this.props.getTransaction({ orgId });
      this.props.getAllPosting({ limit: 100 });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showGetTransaction !== this.props.showGetTransaction) {
      console.log("got here!!", this.props.showGetTransaction);
    }
  }

  populateTransactions = (showGetTransaction) => {
    let startId = 1;
    const transactionsList = showGetTransaction.map((transaction) => {
      const {
        // accountNumber,
        account_number,
        amount,
        teller_cheque_no,
        trans_type,
        account_name,
        id,
        created_at,
      } = transaction;

      return (
        <tr className="tr" key={id}>
          <th scope="row">{startId++}</th>
          <td>{account_name}</td>
          <td>{account_number}</td>
          <td>{`NGN ${amount}`}</td>
          <td>{teller_cheque_no}</td>
          <td className="text-center">
            <strong
              className={`${
                trans_type === "CR" ? "text-success" : "text-danger"
              }`}
            >
              <span className="mr-2">
                {trans_type === "CR" ? (
                  <i className="fas fa-level-up-alt"></i>
                ) : (
                  <i className="fas fa-level-down-alt"></i>
                )}
              </span>
              {trans_type}
            </strong>
          </td>
          <td>{created_at}</td>
        </tr>
      );
    });
    return transactionsList;
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleChange = (event) => {
    console.log("handle change ran...");
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeModal = () => {
    if (this.state.submitAuthorization) {
      return;
    }
    this.setState({ modalIsOpen: false, token: "", status: "" });
  };

  handleChangeFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });

    console.log("selectedFile", this.state.selectedFile);
  };

  onFileUpload = () => {
    console.log("onFileUpload ", this.state.selectedFile);
    if (this.state.selectedFile) {
    //   // Create an object of formData
    const formData = new FormData();

    const { token, userId, phoneId, orgId } = this.state;


    console.log({ token, userId, phoneId, orgId });

      // Update the formData object
      formData.append("userId", userId);
      formData.append("phoneId", phoneId);
      formData.append("orgId", orgId);
      formData.append("token", token);
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      // Display formData value by the key/value pairs
      // for (let pair of formData.entries()) {
      // 	console.log(pair[0], pair[1]);
      // }

      this.setState({ loading: true }, () => {
        this.props.postBulkTransaction(formData, this.handleCallback);

      });
    }
  };

  handleCallback = async (res) => {
    if (res === "success") {
      // await this.refreshList();
      // this.setState({ submitAuthorization: false });
      // this.populateTransactions(data)
      this.closeModal();
      toast.success("File uploaded successful");
      this.setState(
        {
          loading: false,
        },
        () => {}
      );
    } else {
      notify(res, "error");
      this.setState({ loading: false });
    }
  };

  render() {
    // console.log("this.state.selectedFile", this.state.selectedFile);
    // console.log("state", this.state);
    // const tokenError = token?.length !== 6;

    const tokenError = false;

    const { showGetTransaction, isFetching, postFetched, Postings } =
      this.props;

    // console.log({Postings});
    const {
      pageSize,
      currentPage,
      loading,
      submitAuthorization,
      pageHistory,
      token,
    } = this.state;
    const datas = paginate(showGetTransaction, currentPage, pageSize);
    let data = Postings ? Postings.postings : [];
    let _pagination = Postings ? Postings.pagination : {};
    _pagination = { ..._pagination, movement: 3, range: 5 };
    // console.log(datas, 'data');
    console.log(data, "dataaaaaaaaaaaaaaaaaaaaaa");
    return (
      <main>
        <div className="flex-1 ml-auto">
          <label
            className="text-uppercase d-flex align-items-center float-right mr-2"
            color="success"
          >
            &nbsp;
            <small className="font-weight-bold">
              Bulk - [Click to upload]{" "}
            </small>
            {this.state.selectedFile && (
              <small className="text-lowercase font-weight-normal text-info">
                &nbsp;{this.state.selectedFile.name}
              </small>
            )}
            <Input
              accept=".csv"
              onChange={this.handleChangeFile}
              type="file"
              name="id_image"
              className="d-none"
            />
            &nbsp;
            <Button
              className="text-uppercase d-flex align-items-center mr-1"
              color="success"
              onClick={() => {
                if (this.state.selectedFile) {
                  this.setState({ modalIsOpen: true });
                } else {
                  notify("FIle not yet uploaded", "error");
                }
              }}
            >
              Upload!
            </Button>
          </label>
        </div>
        <Container>
          <div className="row">
            {loading && <Loader />}
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              shouldCloseOnOverlayClick={false}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h2
                className="d-none"
                ref={(subtitle) => (this.subtitle = subtitle)}
              >
                Please enter your token
              </h2>
              <div class="d-flex justify-content-end">
                <div
                  onClick={this.closeModal}
                  style={{ padding: 5, cursor: "pointer" }}
                >
                  <i className="fas fa-close text-danger"></i>
                </div>
              </div>
              <div className="form-group">
                <h6>Please enter your token</h6>

                <input
                  type="text"
                  className="form-control"
                  name="token"
                  value={this.state.token}
                  onChange={this.handleChange}
                />
                <button
                  onClick={this.onFileUpload}
                  className="btn btn-primary d-flex justify-content-center align-items-center w-100 mt-2"
                  disabled={tokenError || submitAuthorization}
                >
                  {!submitAuthorization && (
                    <small className="font-weight-bold text-uppercase">
                      {this.state.status === "rejected" ? "Reject" : "Initiate"}
                    </small>
                  )}
                  {submitAuthorization && (
                    <Spinner
                      size={16}
                      spinnerColor={"#ffffff"}
                      spinnerWidth={2}
                      visible={true}
                    />
                  )}
                </button>
              </div>
            </Modal>
          </div>

          <Row>
            <Col>
              <PostingPagination
                {..._pagination}
                caller={this.props.getAllPosting}
              />
            </Col>
          </Row>
          {isFetching && (
            <Row>
              <Col className="d-flex mt-1 justify-content-center">
                <strong>...Fetching postings</strong>
              </Col>
            </Row>
          )}

          <Row>
            {postFetched === false ? (
              <Col>
                <strong>Something went wrong</strong>
              </Col>
            ) : (
              <Col>
                <Table borderless>
                  <thead>
                    <tr className="text-uppercase">
                      <th></th>
                      <th>
                        <small className="font-weight-bold">Account Name</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">
                          Account Number
                        </small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Amount</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Teller No</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Trans type</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Date</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {showGetTransaction && this.populateTransactions(datas)} */}
                    {data && this.populateTransactions(data)}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {showGetTransaction && (
                <Pagination
                  itemCount={showGetTransaction.length}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <PostingPagination
                {..._pagination}
                caller={this.props.getAllPosting}
              />
            </Col>
          </Row>
        </Container>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  showNewAdmin: state.home.showNewAdmin,
  showGetTransaction: state.home.showGetTransaction,
  showNewUser: state.home.showNewUser,
  Postings: state.posting.Postings,
  isFetching: state.posting.isFetching,
  postFetched: state.posting.postFetched,
});

const mapDispatchToProps = {
  getTransaction,
  addNewUser,
  getAllPosting,
  postBulkTransaction,
  getUserIdAndPhoneId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teller);
